import React, { Component } from "react";

import "../../static/styles/app.scss";
import EntityData from "./EntityData";
import Apix from "../../services/apix";
import { TransferContextProvider } from "../../services/transfer-context";

import './i18n';
import { withTranslation } from 'react-i18next';
import BxApi from "../../services/bx-api";

const objectValueComponent = (value) => {
    if (!value || typeof value !== 'object') {
        return (
            <div className="field__value">{value === '' ? '(empty string)' : value}</div>
        )
    }
    if (Array.isArray(value)) {
        return (
            <div className="field__value">
                {value.map((elem, i) => {
                    return (
                        <div className="field">
                            <div className="field__field">{i}</div>
                            <div>
                                {objectValueComponent(elem)}
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
    return Object.keys(value).map(field => {
        return (
            <div className="field">
                <div className="field__field">{field}</div>
                <div>
                    {objectValueComponent(value[field])}
                </div>
            </div>
        )
    })
}

class App extends Component {
    static initiate = false;
    static pollingIsOff = true;
    static bxApi = null;

    state = {
        page: null,
        entity: {},
        activeEntityID: null,
        entityCommon: null,
        initiate: false,
        isTransfer: false,
        placementData: null,
        placementErrorData: null,
        bindDeal: 'null',
        enableAutoClose: true,
        deals: null,
        options: null
    };

    setTransfer = (value) => {
        this.setState({ isTransfer: value });
    };

    transfer = (phone, blind) => {
        const apix = new Apix();

        this.setTransfer(true);

        apix.transfer(phone, blind)
            .then(() => {
                this.setTransfer(false);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    onBindDealChange = (dealId) => {
        this.setState({ bindDeal: dealId });
        if (!this.bxApi) {
            this.bxApi = new BxApi();
        }
        this.state.enableAutoClose && this.bxApi.call(dealId === 'null' ? 'enableAutoClose' : 'disableAutoClose');
        if (dealId === 'null') {
            App.pollingIsOff = true;
            return;
        }
        if (App.pollingIsOff) {
            this.startPolling();
        }
    }

    startPolling = () => {
        App.pollingIsOff = false;
        if (!this.bxApi) {
            this.bxApi = new BxApi();
        }
        const { options: { CALL_ID: callId } } = this.state;
        const iterator = async () => {
            if (App.pollingIsOff) {
                return;
            }
            const activityList = await this.bxApi.rest('crm.activity.list', {
                order:{ "ID": "DESC" },
                filter: { "ORIGIN_ID": "VI_" + callId }
            });
            if (activityList.length > 0) {
                if (this.state.bindDeal === 'null') {
                    return;
                }
                console.log('activityId / dealId', activityList[0].ID, this.state.bindDeal);
                const dealId = parseInt(this.state.bindDeal);
                if (typeof dealId !== 'number') {
                    console.log('не удалось распарсить id дела', this.state.bindDeal);
                    return;
                }
                try {
                    const batchData = this.state.deals.reduce((acc, val) => {
                        const isBindDeal = parseInt(val.ID) === dealId;
                        return {
                            ...acc,
                            [ isBindDeal ? 'addBinding' + val.ID: 'deleteBinding' + val.ID ]: [
                                'crm.activity.binding.' + (isBindDeal ? 'add' : 'delete'),
                                { activityId: parseInt(activityList[0].ID), entityTypeId: 2, entityId: parseInt(val.ID) }
                            ]
                        }
                    }, {});
                    await this.bxApi.batch(batchData);
                } catch (err) {

                }
                this.bxApi.call('enableAutoClose', () => {
                    console.log('close call card');
                    this.bxApi.rest('telephony.externalcall.hide', {
                        CALL_ID: callId,
                        USER_ID: 89
                    });
                });

                return;
            }
            setTimeout(() => {
                iterator();
            }, 1000);
        };

        iterator().then();
    }

    componentDidMount() {
        if (App.initiate) return;

        const apix = new Apix();
        if (!this.bxApi) {
            this.bxApi = new BxApi();
        }

        App.initiate = true;

        new Promise((resolve, reject) => {
            this.bxApi._isBx() ? resolve() : reject("Not found BX24 connector");
        })
            .then(() => this.bxApi.callData())
            .then(response => {
                if (!response.options) {
                    console.debug('placement info data', response);
                    this.setState({placementErrorData: response});
                    return;
                }

                this.bxApi.bindEvents('CallCard::EntityChanged', (callState) => {
                    this.bxApi.rest(`crm.${callState.CRM_ENTITY_TYPE}.get`, { id: callState.CRM_ENTITY_ID })
                        .then((data) => {
                            console.log('change entity', data, callState);
                            const tmpEntity = this.state.entity || {};
                            tmpEntity[data.ID] = {
                                entity: data,
                                type: callState.CRM_ENTITY_TYPE.toLowerCase()
                            };
                            this.setState({ entity: tmpEntity, activeEntityID: String(callState.CRM_ENTITY_ID) });
                        })
                });

                const { options } = response;

                const entityCommon = {
                    type: String(options.CRM_ENTITY_TYPE).toLowerCase(),
                    id: options.CRM_ENTITY_ID,
                };

                this.setState({ entityCommon, placementData: options });

                const methods = {
                    currentUser: ["user.current"],
                    appSettings: ["entity.item.get", { ENTITY: 'settings' }]
                };

                if (parseInt(entityCommon.id)) {
                    methods.entity = [
                        `crm.${entityCommon.type}.get`,
                        { id: entityCommon.id },
                    ];
                }

                return this.bxApi.batch(methods);
            })
            .then(async ({ currentUser, entity, appSettings: appSettingsStorage }) => {
                const { entityCommon } = this.state;

                let appSettingsStorageItem = Array.isArray(appSettingsStorage) ? appSettingsStorage.find(({ NAME }) => NAME === `settings`) : null;
                let appSettingsStorageData = appSettingsStorageItem ? JSON.parse(appSettingsStorageItem.DETAIL_TEXT) : null;
                let enableAutoCloseStorageItem = Array.isArray(appSettingsStorage) ? appSettingsStorage.find(({ NAME }) => NAME === `enableAutoClose`) : null;

                const enableAutoClose = appSettingsStorageData ?
                    appSettingsStorageData.enableAutoClose :
                    enableAutoCloseStorageItem ?
                        enableAutoCloseStorageItem.DETAIL_TEXT === 'true' :
                        true;
                const showActivityBindingToDealFields = appSettingsStorageData ?
                    appSettingsStorageData.showActivityBindingsToDealField :
                    { contact: true, company: true };
                this.setState({ enableAutoClose, showActivityBindingToDealFields });

                if (!enableAutoClose) {
                    console.log('in settings autoclose disabled');
                    this.bxApi.call('disableAutoClose');
                }

                const { options } = await this.bxApi.callData();
                console.log(`options`, options);
                this.setState({ options });

                const newState = {
                    page: "search",
                    currentUser: {
                        ID: currentUser.ID,
                        UF_PHONE_INNER: currentUser.UF_PHONE_INNER,
                    },
                };

                if (entity) {
                    newState.entity = {};
                    newState.entity[entity.ID] = {
                        entity,
                        type: entityCommon.type
                    };
                    newState.activeEntityID = entity.ID;

                    if (entityCommon.type === "lead") {
                        const nowTime = new Date();
                        const date = new Date(entity.DATE_CREATE);
                        const liveSecond = Math.ceil(
                            (nowTime.getTime() - date.getTime()) / 1000
                        );

                        if (liveSecond < 3600) newState.page = "entity";
                    } else if (showActivityBindingToDealFields[entityCommon.type]) {
                        this.fetchDeals(entityCommon.type, entity.ID).then(deals => {
                            this.setState({ deals });
                        });
                    }
                }

                apix.init(
                    "code-bla-bla",
                    this.bxApi.getDomain(),
                    currentUser.UF_PHONE_INNER
                );

                this.setState(newState);
            })
            .catch(err => {
                alert(err);
                console.error("ERROR", err);
            });
    }

    fetchDeals = async (type, id, newOnly = false) => {
        const { deals: oldDealsList } = this.state;
        const lastDealID = newOnly ? (Math.max(...oldDealsList.map(({ ID }) => ID)) + ``) : null;
        console.log(lastDealID);
        const deals = await this.bxApi.rest('crm.deal.list', {
            filter: {
                [type.toUpperCase() + '_ID']: id,
                CLOSED: 'N',
                ...(newOnly ? {
                    '>ID': lastDealID
                } : {})
            }
        });
        console.log('new deals', lastDealID, deals);
        return deals;
    }

    onDealsListOpen = () => {
        const { entityCommon : { type, id }, deals: oldDealsList } = this.state;
        this.fetchDeals(type, id, true)
            .then((deals) => {
                this.setState({ deals: [ ...oldDealsList, ...deals ] });
            });
    }

    render() {
        const { t } = this.props;
        const { page, placementErrorData, entity, activeEntityID, deals } = this.state;

        if (placementErrorData) {
            return (
                <div style={{padding: '20px 40px'}}>
                    <h3>возникла ошибка при обработке события! обратитесь в техподдержку!</h3>
                    <div>placement info data:</div>
                    {objectValueComponent(placementErrorData)}
                </div>
            );
        }

        if (!page) return (
            <div style={{padding: '20px 40px'}}>{t('loading')}</div>
        );

        const currentEntity = entity[activeEntityID];

        if (!currentEntity) {
            return <div style={{padding: '20px 40px'}}>{t('entityNotDetected')}</div>;
        }

        return (
            <React.Fragment>
                <TransferContextProvider
                    value={{
                        is: this.state.isTransfer,
                        set: this.setTransfer,
                        get: this.transfer,
                    }}
                >
                    <EntityData
                        entity={currentEntity.entity}
                        type={currentEntity.type}
                        key={currentEntity.entity.ID}
                        deals={deals}
                        onBindDealChange={this.onBindDealChange}
                        onDealsListOpen={this.onDealsListOpen}
                    />
                </TransferContextProvider>
            </React.Fragment>
        );
    }
}

export default withTranslation()(App);
