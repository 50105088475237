import React, { useState } from "react";
import Select from "../../elements/Select";
import FormField from "../../elements/FormField";
import { withTranslation } from 'react-i18next';

const DealsBinding = ({ deals, onBindDealChange, t, onDealsListOpen }) => {
    const [ value, setValue ] = useState('null');
    const props = {
        field: 'activityBindingToDeal',
        disabled: false,
        multiple: false,
        value,
        change: (val) => {
            if (val === value) {
                return;
            }
            setValue(val);
            onBindDealChange && onBindDealChange(val);
        },
        onOpen: onDealsListOpen
    };
    const options = [ { value: 'null', label: t('default') }, ...(deals || []).map(deal => ({ value: deal.ID, label: deal.TITLE })) ];

    return (
        <FormField label={t('activityBindingToDeal')}>
            <Select {...props} options={options} />
        </FormField>
    );
};

export default withTranslation()(DealsBinding);