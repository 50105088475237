import React, { Component } from "react";

import Wait from "../wait";
import Checkbox from "../../elements/Checkbox";
import { matchFieldTypes } from "../../data/entity_fields";
import Select from "../../elements/Select";
import Money from "../../elements/Money";

import ErrorComponent from "../../elements/error-component";
import Icon from "../../elements/icon";
import logo from "./itgro_logo.png";
import BxApi from "../../services/bx-api";
import CompaniesOfContact from "../CompaniesOfContact";
import CustomField from "../CustomField";
import FloatInput from "../../elements/FloatInput";
import TextField from "../../elements/TextField";
import FormField from "../../elements/FormField";
import IntegerInput from "../../elements/IntegerInput";
import PriceInput from "../../elements/PriceInput";
import Link from "../../elements/Link";
import ArrayOf from "../../elements/ArrayOf";
import { appData } from "../../data/entity_fields";
import DealsBinding from "../DealsBinding";

const STORAGE_NAME = "active_fields";

const crmStatusId = {
    'SOURCE_ID': 'SOURCE',
    'STATUS_ID': 'STATUS'
};

const arrToObj = (arr, fieldName, fieldValue) => arr.reduce((a, v) => ({ ...a, [fieldName(v)]: fieldValue(v)}), {});

class EntityEdit extends Component {
    static inputID = null;

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            options: [],
            fields: null,
            statuses: null,
            fieldsLoaded: false,
        };
    }

    async componentDidMount() {
        const { type, entity, t, i18n } = this.props;
        const bxApi = new BxApi();

        const storage = await bxApi.rest("entity.item.get", { ENTITY: STORAGE_NAME });

        if (storage.length === 0) {
            this.setState({fields: [], fieldsLoaded: true});
            return;
        }

        let selectedFields = storage.find(
            ({ NAME }) => NAME === `${STORAGE_NAME}-${type}`
        );

        if (typeof selectedFields === "object") {
            selectedFields = JSON.parse(selectedFields.DETAIL_TEXT);
        }

        if (!Array.isArray(selectedFields)) {
            console.debug('fields', selectedFields);
            this.setState({fieldsLoaded: true});
            return;
        }

        console.debug('fields', selectedFields);

        const fieldWithoutTranslate = selectedFields.filter(field => !(i18n.exists('common.' + field.field) || i18n.exists(type + '.' + field.field) || field.label));
        let titles = [];

        if (fieldWithoutTranslate.length > 0) {
            titles = await bxApi.rest(`crm.${type}.fields`);
            const storageItem = storage.find(({NAME}) => NAME === `${STORAGE_NAME}-${type}`);
            const formattedSelectedFields = selectedFields.map(field => {
                return {
                    ...field,
                    ...(fieldWithoutTranslate.find(tmpField => tmpField.field === field.field) && titles[field.field] ? {
                        label: titles[field.field].formLabel || titles[field.field].title
                    } : {})
                };
            });
            const data = (typeof formattedSelectedFields === 'string') ? formattedSelectedFields : JSON.stringify(formattedSelectedFields);
            console.debug('change entity', fieldWithoutTranslate, formattedSelectedFields);
            if (storageItem) {
                await bxApi.rest('entity.item.update', {
                    ENTITY: STORAGE_NAME,
                    ID: storageItem.ID,
                    DETAIL_TEXT: data
                });
            } else {
                await bxApi.rest('entity.item.add', {
                    ENTITY: STORAGE_NAME,
                    NAME: `${STORAGE_NAME}-${type}`,
                    DETAIL_TEXT: data
                });
            }
        }

        selectedFields = selectedFields.map(field => {
            return {
                ...field,
                label: i18n.exists(type + '.' + field.field)
                    ? t(type + '.' + field.field)
                    : i18n.exists('common.' + field.field)
                        ? t('common.' + field.field)
                        : field.label || (
                            titles[field.field]
                            ? (titles[field.field].formLabel || titles[field.field].title)
                            : field.field
                        )
            };
        });

        console.debug('selectedFields step 3', selectedFields);

        const statusesDataRequests = {
            ...arrToObj(
                selectedFields
                    .filter(({ type }) => ["select", "crm_status"].includes(type))
                    .map(({field}) => ({
                        field,
                        requestParams: ["crm.status.list", {
                            filter: { ENTITY_ID: field === 'TYPE_ID' ? 'CONTACT_TYPE' : crmStatusId[field] || field },
                        }]
                    })),
                v => v.field,
                v => v.requestParams
            ),
            ...(selectedFields.find(({field}) => field === 'COMPANY_ID') ? {
                COMPANY_NAME: ['crm.company.get', {id: entity.COMPANY_ID}]
            } : {}),
            ...(selectedFields.find(({type}) => type === 'employee') ? {
                users: ['user.get', { FILTER: { USER_TYPE: 'employee', ACTIVE: true } }]
            } : {})
        };
        if (selectedFields.find(({type}) => type === 'crm_currency' || type === 'money')) {
            statusesDataRequests.CURRENCY_ID = ["crm.currency.list", {}];
        }

        selectedFields
            .filter(({type}) => type.startsWith('enumeration'))
            .forEach(({field, id}) => {
                statusesDataRequests[field] = [`crm.${type}.userfield.get`, {id}];
            })

        if (Object.keys(statusesDataRequests).length === 0) {
            this.setState({ fields: selectedFields, fieldsLoaded: true });
            return;
        }

        bxApi.batch(statusesDataRequests)
            .then(({CURRENCY_ID, COMPANY_NAME, users, ...other}) => {
                const data = {};
                if (CURRENCY_ID) {
                    data.CURRENCY_ID = CURRENCY_ID.map(
                        ({ CURRENCY, FULL_NAME }) => ({
                            value: CURRENCY,
                            label: FULL_NAME,
                        })
                    );
                }
                if (COMPANY_NAME) {
                    data.COMPANY_NAME = COMPANY_NAME.TITLE;
                }
                if (users) {
                    data.users = users.map(({ ID, NAME, SECOND_NAME, LAST_NAME }) => ({
                        value: ID,
                        label: [NAME, SECOND_NAME, LAST_NAME].filter(val => typeof val === 'string' && val.length > 0).join(` `)
                    }));
                }
                const dataAdditional = Object.keys(other).reduce((acc, val) => {
                    if (typeof other[val] !== 'object' || Object.keys(other[val]).length === 0) {
                        return acc;
                    }
                    const multiple = other[val].MULTIPLE === 'Y';
                    return {
                        ...acc,
                        [val]: other[val].LIST
                            ? other[val].LIST.map(({ID, VALUE}) => ({value: multiple ? +ID : ID, label: VALUE}))
                            : other[val].map(({ID, NAME, STATUS_ID}) => ({value: multiple ? +ID : STATUS_ID, label: NAME}))
                    };
                }, {});
                this.setState({options: { ...data, ...dataAdditional }, fields: selectedFields, fieldsLoaded: true });
            });
    }

    renderSapsanFields = () => {
        const { changeEntity, entity, type } = this.props;
        const fields = [];

        if (type !== "contact") {
            return '';
        }

        return ([...fields, {
            field: "UF_CRM_1645451252",
            label: "Статус клиента",
            type: "contactCustomField",
            disabled: true,
        }]).map(
            ({ field, label, type, disabled = false, multiple = false }, i) => {
                const props = {
                    field,
                    label,
                    type,
                    disabled,
                    multiple,
                    value: entity[field],
                    change: changeEntity,
                };
                return (
                    <CustomField
                        {...props}
                        id={entity.ID}
                        entityType="contact"
                        key={i}
                    />
                );
            }
        );
    };

    renderFields = () => {
        const { changeEntity, entity, t } = this.props;
        const { fields, fieldsLoaded, options } = this.state;

        if (!fieldsLoaded) {
            return t('loading');
        }

        if (!Array.isArray(fields)) {
            return (
                <div>{t('fieldsLoadingError')}</div>
            )
        }
        if (fields.length === 0) {
            return (
                <div>{t('noCheckedFields')}</div>
            )
        }

        console.debug('all', entity, fields, options);

        return fields.map(
            ({ field, label, type, disabled = false, multiple = false }, i) => {
                const props = {
                    field,
                    type,
                    disabled,
                    multiple,
                    value: entity[field],
                    change: changeEntity,
                };

                switch (matchFieldTypes[field] || type) {
                    case "string":
                    case "textarea":
                        return (
                            <FormField label={label} key={i}>
                                <TextField {...props} />
                            </FormField>
                        );
                    case "integer":
                        if (field.endsWith('_ID')) {
                            return null;
                        }
                        return (
                            <FormField label={label} key={i}>
                                <IntegerInput {...props} />
                            </FormField>
                        );
                    case "double":
                        if (multiple) {
                            return (
                                <FormField label={label} key={i} up>
                                    <ArrayOf label={label} {...props} ItemComponent={FloatInput} valueType="float" />
                                </FormField>
                            );
                        }
                        return (
                            <FormField label={label} key={i}>
                                <FloatInput {...props} />
                            </FormField>
                        );
                    case "select":
                    case "crm_currency":
                    case "crm_status":
                    case "enumeration":
                        return (
                            <FormField label={label} key={i}>
                                <Select {...props} options={options[field]} />
                            </FormField>
                        );
                    case "employee":
                        if (multiple) {
                            return (
                                <FormField label={label} key={i} up>
                                    <ArrayOf
                                        label={label}
                                        {...props}
                                        itemProps={{
                                            options: options.users
                                        }}
                                        ItemComponent={Select}
                                        valueType="float"
                                    />
                                </FormField>
                            );
                        }
                        return (
                            <FormField label={label} key={i}>
                                <Select {...props} options={options.users} />
                            </FormField>
                        );
                    case "char":
                        if (!['Y', 'N', undefined].includes(props.value)) {
                            alert('обнаружено необрабатываемое поле. обратитесь в техподдержку. ' + type + ' - ' + props.field + ' - ' + props.value);
                            return '';
                        }
                        return (
                            <FormField label={label} key={i}>
                                <Checkbox {...props} formatter={['Y', 'N']} />
                            </FormField>
                        );
                    case 'boolean':
                        return (
                            <FormField label={label} key={i}>
                                <Checkbox {...props} formatter={['1', '0']} />
                            </FormField>
                        );
                    case 'price':
                        return (
                            <FormField label={label} key={i}>
                                <PriceInput {...props} />
                            </FormField>
                        );
                    case 'money':
                        if (multiple) {
                            return (
                                <FormField label={label} key={i} up>
                                    <ArrayOf
                                        label={label}
                                        {...props}
                                        itemProps={{currencyList: options.CURRENCY_ID}}
                                        ItemComponent={Money}
                                        valueType="money"
                                    />
                                </FormField>
                            );
                        }
                        return (
                            <FormField label={label} key={i}>
                                <Money {...props} currencyList={options.CURRENCY_ID} />
                            </FormField>
                        );
                    case 'crm_company':
                        return (
                            <FormField label={label} key={i}>
                                <Link {...props} title={options.COMPANY_NAME} />
                            </FormField>
                        );
                    case "CompaniesOfContact":
                        return (
                            <CompaniesOfContact
                                label={label}
                                {...props}
                                id={entity.ID}
                                key={i}
                            />
                        );
                    case "array":
                    case "crm_multifield":
                        return (
                            <FormField label={label} key={i} up>
                                <ArrayOf
                                    label={label}
                                    {...props}
                                    ItemComponent={TextField}
                                    valueType="objectElem"
                                />
                            </FormField>
                        );
                    default:
                        return null;
                }
            }
        );
    };

    render() {
        const { entity, t, saveStatus, deals, onBindDealChange, onDealsListOpen } = this.props;
        const message = {};

        if (entity === null) return <Wait label={t('loading')} />;

        switch (saveStatus) {
            case true:
                message.clazz = "alert alert--success";
                message.text = t('dataIsSaved');
                message.icon = "tick";
                break;
            case false:
                message.clazz = "alert alert--danger";
                message.text = t('saveDataError');
                message.icon = "warning";
                break;
            default:
                break;
        }

        return (
            <ErrorComponent>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <form className="form">
                        {this.renderFields()}
                        {this.renderSapsanFields()}
                        {Array.isArray(deals) && (
                            <DealsBinding deals={deals} onBindDealChange={onBindDealChange} onDealsListOpen={onDealsListOpen} />
                        )}
                    </form>
                </div>
                <div className="btn-group btn-group--fixed btn-group--space-between" style={{ paddingLeft: 30, paddingRight: 30 }}>
                    <a
                        href="https://www.bitrix24.ru/apps/?partner_id=236534&search=Y"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    >
                        <img src={logo} alt="itgro_logo" />
                    </a>

                    <button
                        type="button"
                        onClick={this.props.saveEntity}
                        className="btn btn--success"
                        style={{ position: "relative" }}
                    >
                        {t('save')}
                        {"text" in message && (
                            <div
                                className={message.clazz}
                                style={{
                                    position: "absolute",
                                    top: "0px",
                                    bottom: "0px",
                                    left: "100%",
                                    alignItems: "center",
                                    display: "flex",
                                    marginLeft: 10,
                                }}
                            >
                                <span className="alert-icon">
                                    <Icon
                                        type={message.icon}
                                        inheritColor={true}
                                        inheritSize={true}
                                    />
                                </span>

                                <span>
                                    {message.text === t('dataIsSaved')
                                        ? t('saved')
                                        : message.text}
                                </span>
                            </div>
                        )}
                    </button>
                    <div>{appData.name + ' v' + appData.version}</div>
                </div>
            </ErrorComponent>
        );
    }
}

export default EntityEdit;
